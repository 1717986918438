@tailwind base;

html {
  font-size: 16px;
  background-color: white;
}

.preview-img {
  display: initial;
  width: 100%;
  height: auto;
  margin: 0.25rem;
}

/*
::-webkit-scrollbar {
  width: 0;
}
*/

* {
  border-style: unset;
}


button,
input {
  outline: 0;
}

button:focus,
input:focus {
  outline: 0;
}


@tailwind components;


/* Film roll */

.film-roll--wrapper {
  @apply flex flex-row flex-wrap items-center p-2;
}

.film-roll {
  @apply relative py-1 select-none flex flex-col items-stretch justify-center;
}

.film-roll__overlay {
  @apply absolute inset-0;
  background-color: rgba(0, 0, 0, .5);
}

.film-roll--selected .film-roll__overlay {
  @apply bg-transparent;
}

.film-row--separated {
  @apply py-1 px-px;
  width: 20%;
}

.film-row--separated--active {
  @apply px-0;
  width: calc(20% - 2px);
  border: 1px solid #3f51b5;
}

/* Frame Collection */

.frame-collection {
  @apply flex-1 flex flex-row flex-wrap justify-start items-start p-1;
}

.frame-collection__item {
  @apply flex flex-row-reverse;
}

.frame-collection__item img {
  @apply py-1 box-content;
  padding-right: 2px;
  width: calc(100% - .5rem);
}


.frame-collection__item--active img {
  border: 2px solid theme('colors.secondary.600');
  padding-top: calc(.25rem - 2px);
  padding-bottom: calc(.25rem - 2px);
  padding-right: 0;
}

/* Blocks */

.content-block {
  @apply px-4 py-2 flex flex-row justify-start;
  margin-top: 2px;
}

.content-block.is-dragging-over {
  border-top: 2px dashed #bdbdbd;
  margin-top: 0;
}


.plain-text {
  @apply p-1;
  width: calc((100% - 3rem - 2.5rem) / 2);
}

/* Loaders */


.lds-ring {
  @apply .inline-block .relative .w-20 .h-20;
}

.lds-ring div {
  @apply .absolute .block .w-16 .h-16 .m-2 .rounded-full;
  box-sizing: border-box;
  border: 5px solid theme('colors.theme.400');
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: theme('colors.theme.400') transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.sk-chase {
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2.0s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #000;
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2) {
  animation-delay: -1.0s;
}

.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}

.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2):before {
  animation-delay: -1.0s;
}

.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {

  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }

  100%,
  0% {
    transform: scale(1.0);
  }
}


/* Inputs */

input.underlined--teal {
  border-bottom: 1px solid theme('colors.grey.400');
}

input.underlined--teal:focus {
  border-bottom: 1px solid theme('colors.teal.400');
}

/* Misc */

.content-block--text {
  @apply text-base font-normal leading-normal text-grey-700;
  height: max-content;
  outline: none;
  border: 1px solid theme('colors.grey.400');
  min-height: 9rem;
}

.progress-bar {
  @apply h-2 bg-teal-300;
  transition: width .2s linear;
}

/* Portal page */

.project-concise {
  @apply relative py-2 flex flex-row items-stretch justify-between bg-white;
}

.project-concise.is-active {
  @apply bg-theme-400;
}

.project-concise .checkbox {
  @apply flex-shrink-0 px-4 py-1 self-start cursor-pointer;
  @apply text-grey-600;
}

.project-concise__body {
  @apply flex-1 overflow-x-auto flex flex-col justify-between items-start cursor-pointer;
}

.project-concise__name--wrapper {
  @apply flex flex-row justify-start items-center w-full;
}

.project-concise__name {
  @apply overflow-x-auto font-bold text-base py-1;
  @apply text-grey-900;
}

.project-concise__subtitle-badge {
  @apply mx-2 px-1 text-xs text-grey-400;
}

.project-concise__subtitle-badge.is-active {
  @apply text-theme-400;
}

.project-concise__status {
  @apply font-normal text-grey-600 text-base py-1;
}

a.project-concise__edit {
  @apply p-1 text-grey-800;
}

.project-concise__delete {
  @apply cursor-pointer text-warning-500;
}

.project-concise__preset-name {
  @apply cursor-pointer text-grey-600;
}

.project-concise__actions {
  @apply w-16 flex flex-row-reverse justify-between -mx-1;
}

.project-concise.is-active .checkbox, 
.project-concise.is-active .project-concise__name,
.project-concise.is-active .project-concise__subtitle-badge.is-active,
.project-concise.is-active .project-concise__status,
.project-concise.is-active a.project-concise__edit,
.project-concise.is-active .project-concise__preset-name {
  @apply text-white;
}

/* Markdown content */

.md-content .a {
  @apply text-theme-600;
}

.md-content .h1 {
  @apply text-lg;
}

/* Buttons */

.btn {
  @apply .flex .justify-center .items-center;
  @apply .tracking-wide;
  @apply .cursor-pointer;
  outline: none;
}


.btn:disabled {
  @apply .cursor-not-allowed;
}

.btn:disabled.cursor-wait {
  @apply .cursor-wait;
}

a.btn {
  text-decoration: none !important;
}

.btn:hover {
  outline: none;
}

a.btn:hover {
  text-decoration: none !important;
}

.btn:focus {
  outline: none;
}

.btn--rounded {
  @apply .rounded-full;
}

.btn--theme {
  @apply .bg-theme-400 .text-white;
}

.btn--theme:hover {
  @apply .bg-theme-500;
}

.btn--theme:focus {
  @apply .bg-theme-600;
}

.btn--theme:disabled {
  @apply .bg-theme-200;
}

.btn--theme.btn--textual-plain {
  @apply .bg-transparent .text-theme-500;
}

.nav .btn--theme.btn--textual-plain {
  @apply .text-grey-700;
}

.btn--theme.btn--textual-plain:hover {
  @apply .text-theme-500;
}

.btn--theme.btn--textual-plain:focus {
  @apply .text-theme-600;
}

.btn--theme.btn--textual-plain:disabled {
  @apply .text-theme-200;
}

.btn--secondary {
  @apply .bg-secondary-300 .text-white;
}

.btn--secondary:hover {
  @apply .bg-secondary-400;
}

.btn--secondary:focus {
  @apply .bg-secondary-500;
}

.btn--secondary:disabled {
  @apply .bg-secondary-200;
}

.btn--white {
  @apply .bg-white .text-grey-800;
}

.btn--white:hover {
  @apply .bg-grey-100;
}

.btn--white:focus {
  @apply .bg-grey-200;
}

.btn--light-grey {
  @apply .bg-grey-100 .text-grey-800;
}

.btn--light-grey:hover {
  @apply .bg-grey-200;
}

.btn--light-grey:focus {
  @apply .bg-grey-300;
}

.btn--teal {
  @apply .bg-teal-400 .text-white;
}

.btn--teal:hover {
  @apply .bg-teal-500;
}

.btn--teal:focus {
  @apply .bg-teal-600;
}

.btn--teal:disabled {
  @apply .bg-teal-200;
}

.btn--outlined {
  border: 1px solid #e0e0e0;
  @apply bg-transparent;
}

.btn--outlined:hover {
  @apply .bg-grey-200;
}

.btn--outlined:focus {
  @apply .bg-grey-300;
}


.btn--indigo {
  @apply .bg-indigo-400 .text-white;
}

.btn--indigo:hover {
  @apply .bg-indigo-500;
}

.btn--indigo:focus {
  @apply .bg-indigo-600;
}


.btn--warning {
  @apply .bg-warning-500 .text-white;
}

.btn--warning:hover {
  @apply .bg-warning-600;
}

.btn--warning:focus {
  @apply .bg-warning-700;
}

.btn--warning:disabled {
  @apply .bg-warning-200;
}

.btn--warning.btn--outlined {
  @apply border-warning-500 text-warning-500 bg-transparent;
}

.btn--warning.btn--outlined:hover {
  @apply border-warning-600 text-warning-600;
}

.btn--warning.btn--outlined:focus {
  @apply border-warning-700 text-warning-700;
}

.btn--warning.btn--outlined:disabled {
  @apply border-warning-200 text-warning-200;
}

.btn--outline {
  border: 1px solid lightgrey;
}

.login__btn {
  @apply .rounded .h-10 .text-white .mt-2;
}


@tailwind utilities;

.cursor-hand-scissor {
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24"><path d="M256 480h72.5c4.615 0 9.232-.528 13.722-1.569l123.25-28.57c13.133-3.044 24.995-10.478 33.4-20.933 8.466-10.531 13.128-23.746 13.128-37.21V177.445c0-21.438-11.684-41.333-30.492-51.92l-101.5-57.139c-36.681-20.651-64.548-.478-88.228 28.683l-156.211-60.46c-34.639-13.405-73.672 3.411-87.35 37.709-13.696 34.345 3.326 73.326 38.212 86.829L176 192l-108.5-2.843c-37.22 0-67.5 29.991-67.5 66.855s30.28 66.854 67.5 66.854h102.327c-9.558 28.393 3.681 59.705 31.297 72.775C183.12 434.864 212.126 480 256 480zM364.311 96.271l101.5 57.14c8.753 4.927 14.189 14.137 14.189 24.035v214.272c0 12.91-8.945 24.001-21.754 26.97l-123.25 28.57a28.843 28.843 0 0 1-6.496.742H256c-37.41 0-37.35-55.424 0-55.424a8 8 0 0 0 8-8v-7.143a8 8 0 0 0-8-8h-29c-37.41 0-37.351-55.425 0-55.425h29a8 8 0 0 0 8-8v-7.143a8 8 0 0 0-8-8H67.5c-47.021 0-46.929-69.709 0-69.709H256a8 8 0 0 0 8-8V201.04a8 8 0 0 0-5.112-7.461L97.981 131.305c-43.579-16.867-17.902-81.857 26.037-64.852l172.497 66.761a8.002 8.002 0 0 0 9.098-2.418l22.54-27.757c8.76-10.785 23.966-13.632 36.158-6.768z"/></svg>'), auto;
}

.border-px-grey-400 {
  border: 1px solid #bdbdbd;
}

.border-l-px-grey-400 {
  border-left: 1px solid #bdbdbd;
}

.border-r-px-grey-400 {
  border-right: 1px solid #bdbdbd;
}

.border-b-px-grey-400 {
  border-bottom: 1px solid #bdbdbd;
}

.border-b-px-grey-200 {
  border-bottom: 1px solid #eee;
}


.border-dashed {
  border: 4px dashed #616161;
}

.border-1-blue {
  border: 4px solid #3f51b5;
}

.border-grey-dashed {
  border: 4px dashed #e2e8f0;
}

.child-fill-grey-500 svg {
  fill: #9e9e9e;
}

.child-fill-grey-600 svg {
  fill: #757575;
}


.bg-sepia-light {
  background-color: #fdf6e3;
}

.bg-sepia-shade {
  background-color: #E3DDCC;
}

.bg-overlay {
  background-color: rgba(64, 64, 64, .5);
}


.h-7 {
  height: 1.75rem;
}

.h-80 {
  height: 25rem;
}

.w-mobile {
  width: 25rem;
}

.w-80 {
  width: 20rem;
}

.w-1\/10 {
  width: 10%;
}

.element:first-child .first-element\:opacity-0 {
  opacity: 0;
}

.element:last-child .last-element\:opacity-0 {
  opacity: 0;
}

.element:last-child .last-element\:hidden {
  display: none;
}


.drag-none {
  -webkit-user-drag: none;
}
